<template>
    <div class="discover-details">

        <div class="image-text-title">
            <div class="title" v-html="imageTextObj.name" :style="'font-size:'+imageTextObj.tSize+'px;line-height:'+imageTextObj.tLeading+'px;text-align:'+imageTextObj.tLocation+';color:#'+imageTextObj.tColour"></div>
        </div>
        <div class="image-text-title">
            <div class="info" v-html="imageTextObj.content" :style="'font-size:'+imageTextObj.cSize+'px;line-height:'+imageTextObj.cLeading+'px;text-align:'+imageTextObj.cLocation+';color:#'+imageTextObj.cColour"></div>
        </div>

        <div class="image-text-list">

            <div class="box" :class="'list'+textList.imageList.length" :key="'textList'+key" v-for="(textList,key) in imageTextObj.textList">
                <ul>
                    <li :key="'image'+index" v-for="(image,index) in textList.imageList">
                        <img :src="image.image" class="image"/>
                        <div class="discover-text">
                            <p class="text-title" :style="'font-size:'+textList.bSize+'px;line-height:'+textList.bLeading+'px;text-align:'+textList.bLocation+';transform-origin: '+textList.bLocation+' 0;color:#'+textList.bColour">{{ image.brand }}</p>
                            <p class="text-row" :style="'font-size:'+textList.dSize+'px;line-height:'+textList.dLeading+'px;text-align:'+textList.dLocation+';transform-origin: '+textList.bLocation+' 0;color:#'+textList.dColour">{{ image.description }}</p>
                        </div>
                    </li>
                </ul>
                <p class="text" v-if="textList.content" :style="'font-size:'+textList.cSize+'px;line-height:'+textList.cLeading+'px;text-align:'+textList.cLocation+';color:#'+textList.cColour" v-html="textList.content"></p>
            </div>

        </div>
        <div class="details-list" :style="imageTextObj.productList && imageTextObj.productList.length < 5 ? 'justify-content:left' :''">
            <ul>
                <li :key="key" v-for="(productList,key) in imageTextObj.productList">
                    <div class="list-text">
                        <div class="list-handle">
                            <a href="javascript:" class="handle-unified" :class="productList.isWish === 1?'like':'like-grey'"></a>
                            <a href="javascript:" class="handle-unified" :class="productList.isStar === 1?'star':'star-grey'"></a>
                            <a href="javascript:" class="handle-unified" :class="productList.isShow === 1?'draft':'draft-grey'"></a>
                        </div>
                        <img class="list-img" @click="onQueryDetails(productList.id)" v-lazy="productList.images" :src="productList.images"/>
                        <div class="list-box">
                            <div class="list-status">
                                <span class="is-new" v-if="productList.isNew === 1">{{ $t('menu.isNew') }}</span>
                                <span class="is-sale" v-if="productList.isSale === 1">{{ $t('menu.isSale') }}</span>
                            </div>
                            <span class="box-text bold">{{ productList.brandName }}</span>
                            <span class="box-text">{{ productList.name }}</span>
                            <span class="box-text bold">{{ currencyCode === 'cny'?onCharacterPrice(productList.price) : productList.price }}</span>
                            <!--                            <span class="box-text">{{ productList.productId }}</span>-->
                        </div>
                    </div>
                </li>
            </ul>
        </div>

    </div>

</template>
<script>
import ImageText from './imageText'
export default ImageText
</script>
<style lang="less">
@import "imageText";
</style>
