/**
 * 图文
 */
import Vue from 'vue'
import { getMarketContentService } from "@/service/market-service";
import { mapMutations, mapState } from "vuex";
import VueLazyLoad from 'vue-lazyload'
import { onCharacterPrice } from "@/utils/tools";
import router from "@/router";
Vue.use(VueLazyLoad,{
    loading:require('@/assets/Loading/seize-seat-img.jpg')
})
const ImageText = {
    name:'ImageText',
    computed: {
        ...mapState({
            currencyCode: state => state.system.currencyCode, //获取状态管理币种代码
            productId: state => state.product.productId //获取状态管理产品id
        }),
    },
    data() {
        return {
            onCharacterPrice:onCharacterPrice,
            imageTextObj:{},
        }
    },
    created() {
        if(this.$route.query.id === '' || this.$route.query.id === undefined){
            router.back()
        }else{
            this.onMarketContent(this.$route.query.id).then()
        }
    },
    mounted() {
        document.documentElement.scrollTop = document.body.scrollTop = 0 //定位滚动条到头部
    },
    methods: {
        ...mapMutations('product',['SET_PRODUCT_ID']),
        /**
         * 跳转到详情页
         * @param id
         */
        onQueryDetails(id){
            this.SET_PRODUCT_ID(id)
            this.$router.push('/product/details?id='+id)
        },

        /**
         * 拉取图文
         * @returns {Promise<void>}
         */
        async onMarketContent(id) {
            try {
                this.loadingVisible = true
                let params = {
                    id:id,
                }
                const { code,data } = await getMarketContentService(params)
                if(code === 1){
                    this.imageTextObj = data
                }
                if(code === 2001){  //图文id无内容
                    router.back()
                }
            }catch (error){
                console.log(error)
            }
        },
    },
}
export default ImageText
